/* Font Poppins */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Courier+Prime:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: 300;
  color: #343A40;
  background-color: #F8F9FA;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

a {
  font-size: 1rem;
  color: #EF6215;
  text-decoration: none;
  background-color: transparent;
  transition: color 0.5s;
}

a:hover {
  color: #BD4D11;
}

.link-light {
  transition: color 0.5s;
  color: #F8F9FA95;
}

.link-light:hover {
  color: #F8F9FA;
}

h1 {
  font-weight: 100;
  font-size: 2.488rem;
}

h2 {
  padding-top: 1rem;
  font-weight: 100;
  font-size: 2.074rem;
}

h3 {
  padding-top: 1rem;
  font-weight: 200;
  font-size: 1.728rem;
}

h4 {
  font-weight: 300;
  font-size: 1.44rem;
}

h5 {
  font-weight: 400;
  font-size: 1.2rem;
}

.icon-xsmall {
  font-size: 1.25rem;
}

.icon-small {
  font-size: 1.728rem;
}

.icon-large {
  font-size: 2.488rem;
}

.text-thin {
  font-weight: 100 !important;
}

.text-highlight-bold {
  font-weight: 700;
  display: inline-block;
  color: #F8F9FA;
  background-color: #EF6215;
  padding: 4px 20px;
}

strong {
  font-weight: 600;
  font-size: 1rem;
}

.subtitle {
  font-weight: 100;
  font-size: 1.728rem;
  color: #343A40;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.text-truncate-2-line {
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/* Background Colors */
.color-universal-white {
  background-color: #FFF;
}

.color-universal-black {
  background-color: #000;
}

.color-orange {
  background-color: #EF6215;
}

.color-orange-hover {
  background-color: #BD4D11;
}

.color-orange-disable {
  background-color: #F2925E;
}

.color-yellow {
  background-color: #FDD12D;
}

.color-yellow-hover {
  background-color: #C9A624;
}

.color-yellow-disable {
  background-color: #FDE079;
}

.color-light {
  background-color: #F8F9FA;
}

.color-light-hover {
  background-color: #E2E6EA;
}

.color-light-disable {
  background-color: #FAFCFC;
}

.color-dark {
  background-color: #343A40;
}

.color-dark-hover {
  background-color: #23272B;
}

.color-dark-disable {
  background-color: #7A7E83;
}

/* Text Colors */
.text-heading {
  display: inline-block;
  color: #F8F9FA;
  background-color: #343A40;
  padding: 4px 20px;
  border-radius: 0.25rem !important;
}

.text-light {
  color: #F8F9FA;
}

.text-dark {
  color: #343A40;
}

/* Buttons */
.btn-primary {
  background-color: #ef6115;
  border: 1px solid #EF6215;
  display: inline-block;
  color: #ffffff;
  padding: 20px 30px;
  text-decoration: none;
  transition: background-color 0.5s ease-out;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  background-color: #bd4d11 !important;
  border-color: #EF6215 !important;
}

.btn-default {
  background-color: transparent;
  border: 1px solid #EF6215;
  display: inline-block;
  color: #EF6215;
  padding: 20px 30px;
  text-decoration: none;
  transition: background-color 0.5s ease-out;
}

.btn-default:hover {
  background-color: #ef611520;
}



/* Cards */
.card {
  overflow: hidden;
}

.card a {
  color: #EF6215;
}

.card a:hover {
  color: #EF6215;
}

.card img {
  transition: transform .65s;
}

.card:hover img {
  transform: scale(1.1);
}

.card-img-overlay {
  top: auto;
  background-color: #343A4050;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  transition: backdrop-filter 0.5s ease, background-color 0.5s ease;
}

.card:hover .card-img-overlay {
  top: auto;
  background-color: #23272B50;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}

/* Header */
.home-hero-bg {
  background-color: #23272B;
  background-image: linear-gradient(to bottom, #23272B, rgb(26, 26, 26));
  background-image: -webkit-linear-gradient(to bottom, #23272B, rgb(26, 26, 26));
  background-image: -moz-linear-gradient(to bottom, #23272B, rgb(26, 26, 26));
}

/* Timeline */
.timeline {
  position: relative;
  margin: 0 auto;
}

.timeline::after {
  content: '';
  position: absolute;
  width: 2px;
  background-color: #EF6215;
  top: 0;
  bottom: 0;
  left: 1rem;
  margin-left: -3px;
}

.timeline section {
  padding-left: 4rem;
  padding-bottom: 1rem;
  padding-top: 1rem;
  position: relative;
}

.timeline section::after {
  content: '';
  position: absolute;
  width: 16px;
  height: 16px;
  background-color: #F8F9FA;
  border: 2px solid #EF6215;
  top: 2.5rem;
  border-radius: 50%;
  z-index: 1;
  left: 6px;
}

/* Jumbotron */
.line-gradient {
  height: 2px !important;
  background-color: #EF6215;
  background-image: linear-gradient(to right, #fdd12d, #ef6125);
  background-image: -webkit-linear-gradient(to right, #fdd12d, #ef6125);
  background-image: -moz-linear-gradient(to right, #fdd12d, #ef6125);
}

.object-fit-cover {
  object-fit: cover;
}

.max-height {
  max-height: 400px;
}

/* Animation */
.typewriter p {
  color: #fff;
  font-family: 'Courier Prime', monospace;
  font-weight: 700;
  overflow: hidden;
  /* Ensures the content is not revealed until the animation */
  border-right: .15em solid #ef6125;
  /* The typwriter cursor */
  white-space: nowrap;
  /* Keeps the content on a single line */
  margin: 0 auto;
  /* Gives that scrolling effect as the typing happens */
  letter-spacing: .15em;
  /* Adjust as needed */
  animation:
    typing 2.5s steps(20, end),
    blink-caret .5s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0
  }

  to {
    width: 100%
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {

  from,
  to {
    border-color: transparent
  }

  50% {
    border-color: #fdd12d
  }
}

/* Icons */
img.icon-large{
  max-height: 128px;
}
img.icon-small{
  max-height: 24px;
  width: 24px;
}